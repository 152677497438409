import * as React from 'react';

import { graphql } from 'gatsby';

import VisuelComponent from '../../../../components/ContentType/VisuelComponent/VisuelComponent';
import PageBannerStyle from '../../../../components/PageBannerStyle/PageBannerStyle';
import Seo from '../../../../components/Seo/Seo';
import VideoYoutube from '../../../../components/Video/VideoYoutube';
import Layout from '../../../../Layout';
import TitlePage from '../../../../Molecules/TitlePage/TitlePage';
import TitleSection from '../../../../Molecules/TitleSection/TitleSection';
import { getImage, youtube_parser, getBreadCrumb } from '../../../../Utils/Utils';

import './styles.scss';

let classNames = require('classnames');

const ColasHistorySingle = ({ data }) => {
  const detailHistoryData = data?.detailHistoryData?.edges[0]?.node;
  const imagesArray = data?.allImages?.edges;
  const allMenu = data.allMenu.edges;
  const breadCrumb = getBreadCrumb(allMenu, detailHistoryData.path?.alias);

  return (
    <Layout imagesArray={imagesArray}>
      <Seo
        title={detailHistoryData.title}
        description={detailHistoryData.body?.processed}
      />
      <div className={classNames('page_template', 'colas_history_inner_page')}>
        <PageBannerStyle
          visuel={getImage(imagesArray, detailHistoryData?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp?.fluid} scroll={true}
          breadcrumbData={{
            //grandParentPage: { title: 'Acceuile', url: '/' },
            gparent: { title: breadCrumb?.grandParent?.name, url: breadCrumb?.grandParent?.link },
            parentPage: { title: breadCrumb?.parent?.name, url: breadCrumb?.parent?.link },
            currentPage: { title: breadCrumb?.current?.name, url: breadCrumb?.current?.link },
            locale: detailHistoryData.langcode
          }}

        >
          <div className="wrapper_page">
            <TitlePage
              color="color_white"
              title={detailHistoryData.title}
              description={detailHistoryData.body?.processed}
            />
          </div>
        </PageBannerStyle>
        {detailHistoryData.relationships?.field_blocs?.map((block, i) => {
          switch (block.__typename) {
            case 'block_content__basic':
              return (
                <section className={classNames('section_content', 'section_lauch_wattway')} key={i}>
                  <div className="wrapper_page_xs">
                    <TitleSection
                      title={block.field_title?.processed}
                    />
                    <div dangerouslySetInnerHTML={{ __html: block.body?.processed }}></div>
                    <VisuelComponent
                      extremeLeft={true}
                      visuel={getImage(imagesArray, block?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp}
                      size="two_third"
                      alt=""
                    />
                  </div>
                </section>
              );

            case 'block_content__block_video':

              return (
                <section className="section_content" key={i}>
                  {<div className="wrapper_page_xs">
                    <TitleSection
                      title={block.field_title?.processed}
                    />
                    <div dangerouslySetInnerHTML={{ __html: block.body?.processed }}></div>
                    <div className="video_container">
                      <VideoYoutube
                        cover={getImage(imagesArray, block?.relationships?.field_image?.drupal_internal__mid)?.publicURL}
                        videoID={youtube_parser(block.field_link?.uri)}
                        obj={{
                          height: '390',
                          width: '640',
                          playerVars: {
                            // https://developers.google.com/youtube/player_parameters
                            autoplay: 0,
                            controls: 0,
                            disablekb: 1,
                            rel: 0,
                            showinfo: 0,
                          },
                        }}
                      />
                    </div>
                  </div>}
                </section>
              );
          }
        })}

      </div>
    </Layout>
  );
};

export const query = graphql`
query DetailHistoryTemplateQuery($locale: String!, $slug: String!, $imagesID: [Int!]) {
  detailHistoryData: allNodeHistoire(
    filter: {langcode: {eq: $locale}, path: {alias: {eq: $slug}}}
  ) {
    edges {
      node {
        title
        langcode
        path {
          alias
          langcode
        }
        body {
          processed
        }
        relationships {
          field_image {
            drupal_internal__mid
          }
          field_blocs {
            __typename
            ... on Node {
              ... on block_content__basic {
                field_title {
                  processed
                }
                field_no_padding
                relationships {
                  field_image {
                    drupal_internal__mid
                  }
                }
                body {
                  processed
                }
              }
              ... on block_content__block_video {
                body {
                  processed
                }
                field_title {
                  processed
                }
                field_link {
                  title
                  uri
                }
                relationships {
                  field_image {
                    drupal_internal__mid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  allImages: allMediaImage(
    filter: {langcode: {eq: "fr"}, drupal_internal__mid: {in: $imagesID}}
  ) {
    edges {
      node {
        drupal_internal__mid
        relationships {
          field_media_image {
            localFile {
              publicURL
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
                fixed(width: 332, height: 332) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        langcode
      }
    }
  }

  allMenu :   allMenuLinkContentMenuLinkContent(
    filter: {langcode: {eq: $locale}}
  ) {
    edges {
      node {
        drupal_parent_menu_item
        drupal_id
        langcode
        link {
          url
        }
        menu_name
        title
      }
    }
  }
}
`;

export default ColasHistorySingle;
