import React from 'react';

import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import BGArrowPointerBottomMobile from '../../assets/images/bg-page-banner-v2-bottom-mobile.inline.svg';
import BGArrowPointerBottomLeft from '../../assets/images/bg-page-banner-v2-bottom.inline.svg';
import BGArrowPointerTopMobile from '../../assets/images/bg-page-banner-v2-top-mobile.inline.svg';
import BGArrowPointerTopLeft from '../../assets/images/bg-page-banner-v2-top.inline.svg';
import { ShapeYellowArrowBanner } from '../../Atoms/Icons/Icons';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import ScrollIcon from '../../Molecules/ScrollIcon/ScrollIcon';

import './PageBannerStyle.scss';
let classNames = require('classnames');

const PageBannerStyle = ({ visuel, breadcrumbData, children }) => {
  return (
    <div className="page_banner_v2">
      <div className="visuel_container">
        <div className="visuel">
          {visuel &&
            <Img
              className="only_desktop"
              style={{ width: '100%' }}
              imgStyle={{ objectFit: 'contain' }}
              fluid={visuel}
            />
          }
          {visuel &&
            <Img
              className="only_mobile_tablet"
              style={{ width: '100%', height: '100%' }}
              imgStyle={{ objectFit: 'cover' }}
              fluid={visuel}
            />
          }
        </div>
        <div className={classNames('visuel_overlay')}>
          <div className="wrapper_page">
            <Breadcrumb
              page_banner={true}
              data={breadcrumbData}
            />
          </div>
        </div>
      </div>
      <div className={classNames('intro_design')}>
        <div className={classNames('shaped_background_banner')}>
          <div className={classNames('bg_arrow_pointer_top')}>
            <BGArrowPointerTopMobile className="only_mobile_tablet" />
            <BGArrowPointerTopLeft className="only_desktop" />
          </div>
          <div className="scroll_icon_container">
            <ScrollIcon />
          </div>
          <div className="center_content">
            {children}
          </div>
          <div className={classNames('bg_arrow_pointer_bottom')}>
            <BGArrowPointerBottomMobile className="only_mobile_tablet" />
            <BGArrowPointerBottomLeft className="only_desktop" />
          </div>
        </div>
        <ShapeYellowArrowBanner className="shape_yellow_banner_bottom" />
      </div>
    </div>
  );
};

PageBannerStyle.propTypes = {
  visuel: PropTypes.object.isRequired
};

export default PageBannerStyle;
